<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Inspecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">TEP</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Inspecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11">
                    <h5>Inspecciones</h5>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="save"
                        v-if="$store.getters.can('tep.inspecciones.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card-body">
                  <h6>Filtros</h6>
                  <div class="row">
                    <div class="form-group col-md-2">
                      <label>N° Inspección</label>
                      <input
                        type="number"
                        v-model="filtros.id"
                        placeholder="N° inspeccion"
                        label="id"
                        class="form-control form-control-sm p-0"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>N° Turno</label>
                      <input
                        type="number"
                        v-model="filtros.turno_id"
                        placeholder="N° Turno"
                        label="turno_id"
                        class="form-control form-control-sm p-0"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>N° Viaje</label>
                      <input
                        type="number"
                        v-model="filtros.viaje_id"
                        placeholder="N° viaje"
                        label="viaje_id"
                        class="form-control form-control-sm p-0"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Rango Fechas</label>
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_inicio"
                            @change="getIndex()"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                            @change="getIndex()"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Vehículo</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="listasForms.slct_vehiculo"
                        placeholder="Placa"
                        label="placa"
                        class="form-control form-control-sm p-0"
                        :options="vehiculos"
                        :filterable="false"
                        @search="buscarCabezotes"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Formato</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="listasForms.slct_formato_inspeccion"
                        placeholder="Formato Inspección"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="formatos_inspeccion"
                        :filterable="false"
                        @search="buscarFormatosInspeccion"
                        @input="getIndex()"
                      ></v-select>
                    </div>

                    <div class="form-group col-md-3">
                      <label>Sitio</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="listasForms.slct_sitio"
                        placeholder="Sitio"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="sitios"
                        :filterable="false"
                        @search="buscarSitios"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Empresa</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="listasForms.slct_empresa"
                        placeholder="Empresas"
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="empresas"
                        :filterable="false"
                        @search="buscarEmpresas"
                        @input="getIndex()"
                      ></v-select>
                    </div>

                    <div class="form-group col-md-5">
                      <label>Conductor</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="listasForms.slct_conductor"
                        placeholder="Conductor"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="conductores"
                        :filterable="false"
                        @search="buscarConductor"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                        @change="getIndex()"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          >{{ estado.descripcion }}</option
                        >
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado Documento</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado_documento"
                        @change="getIndex()"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados_documentos"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          >{{ estado.descripcion }}</option
                        >
                      </select>
                    </div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn bg-navy"
                          @click="getIndex()"
                        >
                          <i class="fas fa-sync"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <table
                    class="table table-bordered table-striped table-hover table-sm"
                    style="font-size: 0.85em"
                  >
                    <thead class="thead">
                      <tr>
                        <th>N° Inspección</th>
                        <th>N° Turno</th>
                        <th>N° Viaje</th>
                        <th class="text-center">Solicitud</th>
                        <th>
                          Fecha
                        </th>
                        <th>
                          Formato
                        </th>

                        <th>
                          Sitio
                        </th>

                        <th>
                          Empresa
                        </th>
                        <th>
                          Vehículo
                        </th>
                        <th>
                          Conductor
                        </th>
                        <th style="width: 30px">
                          Estado
                        </th>
                        <th style="width: 30px">
                          Est. Doc
                        </th>
                        <th class="text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="inspeccion in inspecciones.data"
                        :key="inspeccion.id"
                      >
                        <td>{{ inspeccion.id }}</td>
                        <td>{{ inspeccion.tep_turno_id }}</td>
                        <td class="text-center">
                          {{ inspeccion.tep_viaje_id }}
                        </td>
                        <td class="text-center">
                          {{
                            inspeccion.solicitud
                              ? inspeccion.solicitud.id
                              : "Preop"
                          }}
                        </td>
                        <td>{{ inspeccion.fecha_inicio }}</td>
                        <td>
                          {{
                            inspeccion.formato_inspeccion
                              ? inspeccion.formato_inspeccion.nombre
                              : ""
                          }}
                        </td>

                        <td>
                          {{ inspeccion.sitio ? inspeccion.sitio.nombre : "" }}
                        </td>
                        <td>
                          {{
                            inspeccion.empresa
                              ? inspeccion.empresa.razon_social
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            inspeccion.vehiculo ? inspeccion.vehiculo.placa : ""
                          }}
                        </td>
                        <td>
                          {{
                            inspeccion.conductor
                              ? `${inspeccion.conductor.nombres} ${inspeccion.conductor.apellidos}`
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="[
                              inspeccion.estado == 1
                                ? 'badge-secondary'
                                : inspeccion.estado == 2
                                ? 'badge-success'
                                : inspeccion.estado == 3
                                ? 'badge-success'
                                : inspeccion.estado == 4
                                ? 'badge-danger'
                                : inspeccion.estado == 5
                                ? 'bg-olive'
                                : '',
                            ]"
                          >
                            {{
                              inspeccion.estado == 1
                                ? "Creada"
                                : inspeccion.estado == 2
                                ? "Aprobada"
                                : inspeccion.estado == 3
                                ? "Autorizada"
                                : inspeccion.estado == 5
                                ? "Aprobada con novedad"
                                : "Rechazada"
                            }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="[
                              inspeccion.estado_documento == 1
                                ? 'badge-warning'
                                : 'badge-success',
                            ]"
                          >
                            {{
                              inspeccion.estado_documento == 1
                                ? "Borrador"
                                : "Definitivo"
                            }}
                          </span>
                        </td>
                        <td style="width: 50px" class="text-right">
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('tep.inspecciones.show')"
                              @click="edit(inspeccion.id)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-danger"
                              v-if="
                                $store.getters.can('tep.inspecciones.verPdf') &&
                                  inspeccion.estado_documento == 2
                              "
                              @click="verPDF(inspeccion.id)"
                            >
                              <i class="fas fa-file-pdf"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="inspecciones.total">
                    <p>
                      Mostrando del
                      <b>{{ inspecciones.from }}</b> al
                      <b>{{ inspecciones.to }}</b> de un total:
                      <b>{{ inspecciones.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger"
                        >No hay registros para mostrar</span
                      >
                    </p>
                  </div>
                  <pagination
                    :data="inspecciones"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";

export default {
  name: "TepInspeccionIndex",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      inspecciones: {},
      filtros: {
        id: null,
        viaje_id: null,
        turno_id: null,
        fecha_inicio: null,
        fecha_fin: null,
        formato_inspeccion_id: null,
        punto_gestion_id: null,
        sitio_type: null,
        sitio_type2: null,
        empresa_id: null,
        vehiculo_id: null,
        conductor_id: null,
        estado: null,
        estado_documento: null,
      },
      listasForms: {
        slct_formato_inspeccion: [],
        slct_puntos_gestion: [],
        slct_sitio: [],
        slct_empresa: [],
        slct_vehiculo: [],
        slct_conductor: [],
        estados: [],
        estados_documentos: [],
        sitio_types: [],
      },
      formatos_inspeccion: [],
      puntos_gestion: [],
      sitios: [],
      empresas: [],
      vehiculos: [],
      conductores: [],
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.sitio_id = null;
      if (this.listasForms.slct_sitio) {
        this.filtros.sitio_id = this.listasForms.slct_sitio.id;
      } else {
        this.listasForms.slct_sitio = null;
      }

      // Filtro para Formato de Inspección
      this.filtros.formato_inspeccion_id = null;
      if (this.listasForms.slct_formato_inspeccion) {
        this.filtros.formato_inspeccion_id = this.listasForms.slct_formato_inspeccion.id;
      }

      // Filtro para Empresa
      this.filtros.empresa_id = null;
      if (this.listasForms.slct_empresa) {
        this.filtros.empresa_id = this.listasForms.slct_empresa.id;
      }

      // Filtro para Vehículos
      this.filtros.vehiculo_id = null;
      if (this.listasForms.slct_vehiculo) {
        this.filtros.vehiculo_id = this.listasForms.slct_vehiculo.id;
      }

      // Filtro para Conductores
      this.filtros.conductor_id = null;
      if (this.listasForms.slct_conductor) {
        this.filtros.conductor_id = this.listasForms.slct_conductor.id;
      }

      // Se valida que la fecha de finalización no sea menor que la fecha de inicio
      if (this.filtros.fecha_inicio && this.filtros.fecha_fin) {
        this.validaFechas();
      }

      axios
        .get("/api/tep/inspecciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.inspecciones = response.data;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    buscarPuntosGestion(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/puntos_gestion/lista?punto_gestion=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.puntos_gestion = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarFormatosInspeccion(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url =
          "api/admin/formatosInspeccion/lista?formato_inspeccion=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.formatos_inspeccion = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarCabezotes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.vehiculos = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/38").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEstadosDocumentos() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados_documentos = response.data;
      });
    },

    save() {
      return this.$router.push({
        name: "/Tep/InspeccionForm",
      });
    },

    edit(inspeccion_id) {
      return this.$router.push({
        name: "/Tep/InspeccionForm",
        params: {
          inspeccion_id: inspeccion_id,
        },
      });
    },

    //lista sitio type

    getSitioType() {
      // sitio 1, punto gestion 2
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    verPDF(inspeccion_id) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/tep/inspecciones/pdf/" + inspeccion_id,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getSitioType();
    this.getEstadosDocumentos();
  },
};
</script>
